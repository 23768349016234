import React, { useEffect, useState } from "react";
import 'src/styles/workOrder/mixing-table.css';
import { MaterialType, MixingFormulation } from "@twinsketch/topika-model";
import { BinderSolutionData, MixingSolutionData, defaultSlurryMixingData } from "./MixingTableData";
import RenderCell, { FieldConfig } from "./RenderCell";
import { FromulationToMixingHelper } from "./FormPage";
import { percentageStringToNumber } from "./helper";

interface MixingTableProps {
    activeMaterialKg: number;
    materialRatio: Record<MaterialType, number>;
    formulation: MixingFormulation;
    binderData: BinderSolutionData;
    mixingData: MixingSolutionData;
}

const fieldConfig: FieldConfig = {
    subMaterials: {
        weight: { cellType: "calculated", type: "number" },
        density: { cellType: "editable", type: "number" },
        volume: { cellType: "calculated", type: "number" },
        leadTime: { cellType: "editable", type: "text" },
        remark: { cellType: "editable", type: "text" },
    },
    details: {
        weight: { cellType: "editable", type: "number" },
        density: { cellType: "disabled", type: "number" },
        volume: { cellType: "disabled", type: "number" },
        leadTime: { cellType: "editable", type: "text" },
        remark: { cellType: "editable", type: "text" },
    },
    total: {
        weight: { cellType: "calculated", type: "number" },
        density: { cellType: "calculated", type: "number" },
        volume: { cellType: "calculated", type: "number" },
    },
};

const MixingTable: React.FC<MixingTableProps> = ({
    activeMaterialKg,
    materialRatio,
    formulation: formulation,
    binderData,
    mixingData,
}) => {
    const [localMixingData, setLocalMixingData] = useState(mixingData);

    useEffect(() => {
        setLocalMixingData(mixingData);
    }, [mixingData]);


    const [currentFormulation, setLocalProducts] = useState(formulation);
    const [mixingSolutionData, setMixingSolutionData] = useState<MixingSolutionData>(
        defaultSlurryMixingData
    );

    const handleInputChange = (path: string[], value: any) => {
        const updatedProducts = { ...currentFormulation };
        let current: any = updatedProducts;

        // Navigate to the specific field using the path
        for (let i = 0; i < path.length - 1; i++) {
            current = current[path[i]];
        }
        current[path[path.length - 1]] = value;

        setLocalProducts(updatedProducts);
    };

    const calculateTotals = (subMaterials: any[]) => {
        const total = subMaterials.find((mat) => mat.name === "Total");
        const filteredSubMaterials = subMaterials.filter((mat) => mat.name !== "Total");

        const totalWeight = filteredSubMaterials.reduce((sum, mat) => sum + (mat.weight || 0), 0);
        const totalVolume = filteredSubMaterials.reduce((sum, mat) => sum + (mat.volume || 0), 0);
        const averageDensity = totalVolume > 0 ? totalWeight / totalVolume : 0;

        return {
            name: "Total",
            colSpan: 3,
            weight: parseFloat(totalWeight.toFixed(2)),
            density: parseFloat(averageDensity.toFixed(2)),
            volume: parseFloat(totalVolume.toFixed(2)),
            leadTime: "",
            remark: "",
        };
    };

    const calculateSolidTotals = (subMaterials: any[]) => {
        console.log("binder", binderData);
        const binderSolidContent = binderData.subProcesses[0].materials[0].subMaterials;
        const binderMaterial = binderSolidContent.filter((mat) => mat.name === "PVdF")[0];
        const binderSolidWeight = binderMaterial.weight;

        const preMix1 = localMixingData.subProcesses[0].materials[0].subMaterials;
        const solid = preMix1.filter((mat) => mat.name === "Solid")[0];
        const solidweight = solid.weight;

        const preMix2 = localMixingData.subProcesses[0].materials[1].subMaterials;
        const ca = preMix2.filter((mat) => mat.name === "LITXHP")[0];
        const caWeight = ca.weight;

        const mainMiz = localMixingData.subProcesses[1].materials[0].subMaterials;
        // const am = mainMiz.filter((mat) => mat.name === "LITXHP")[0];
        const mainMixWeight = mainMiz.reduce((sum, mat) => sum + (mat.weight || 0), 0);
        console.log(binderSolidWeight, solidweight, caWeight, mainMixWeight);
        console.log(binderSolidWeight + solidweight + caWeight + mainMixWeight);
        return;
    };


    // const activeMaterialKg = formulationDetails.activeMaterialKg;
    const additiveRatio = materialRatio["Additive"];
    const activeMaterialRatio = materialRatio["Active Material"];
    const caRatio = materialRatio["Conducting Agent"];

    useEffect(() => {
        setMixingSolutionData((prevData) => {
            const updatedData = { ...prevData };
            const subMaterials = updatedData.subProcesses[0].materials[0].subMaterials;

            // Update NMP weight and volume
            const solid = subMaterials.find((mat) => mat.name === "Solid");
            let solidRemark = percentageStringToNumber(solid.remark);
            if (solid) {
                const solidWeight = (activeMaterialKg * (additiveRatio / activeMaterialRatio));
                solid.weight = parseFloat(solidWeight.toFixed(2));
                solid.volume = solid.density
                    ? parseFloat((solid.weight / solid.density).toFixed(2))
                    : null;
            }

            const nmp = subMaterials.find((mat) => mat.name === "NMP");
            if (nmp) {
                const nmpWeight = solid.weight / solidRemark - solid.weight;
                nmp.weight = parseFloat(nmpWeight.toFixed(2));
                nmp.volume = nmp.density
                    ? parseFloat((nmp.weight / nmp.density).toFixed(2))
                    : null;
            }
            // Recalculate totals
            updatedData.total = calculateTotals(subMaterials)

            const subMaterialsCA = updatedData.subProcesses[0].materials[1].subMaterials;
            const ca = subMaterialsCA.find((mat) => mat.name === "LITXHP");
            if (ca) {
                const caWeight = (activeMaterialKg * (caRatio / activeMaterialRatio));
                console.log(caWeight)
                ca.weight = parseFloat(caWeight.toFixed(2));
                ca.volume = ca.density
                    ? parseFloat((ca.weight / ca.density).toFixed(2))
                    : null;
            }

            calculateSolidTotals(subMaterials);
            // Recalculate
            return updatedData;
        });
    }, [activeMaterialKg, activeMaterialRatio, additiveRatio]);

    return (
        <tbody>
            {/* Slurry Mixing */}
            {[0, 1].map((processIndex) => (
                <>
                    {/* Render materials and their sub-materials */}
                    {defaultSlurryMixingData.subProcesses[processIndex].materials.map((material, materialIndex) =>
                        material.subMaterials.map((subMaterial, subIndex) => (
                            <tr key={`${processIndex}-${materialIndex}-${subIndex}`}>
                                {/* Render the main process and sub-process for the first row of the first material */}
                                {materialIndex === 0 && subIndex === 0 && (
                                    <>
                                        {processIndex === 0 && (
                                            <td rowSpan={defaultSlurryMixingData.rowSpan || 0}>{defaultSlurryMixingData.process}</td>
                                        )}
                                        <td rowSpan={defaultSlurryMixingData.subProcesses[processIndex].rowSpan || 0}>
                                            {defaultSlurryMixingData.subProcesses[processIndex].name}
                                        </td>
                                    </>
                                )}
                                {/* Render the material name for the first sub-material of each material */}
                                {subIndex === 0 && (
                                    <td rowSpan={material.rowSpan || 0}>{material.name}</td>
                                )}

                                {/* Render sub-material properties */}
                                <td>{subMaterial.name}</td>
                                <RenderCell
                                    value={subMaterial.weight}
                                    path={[
                                        "subProcesses",
                                        `${processIndex}`,
                                        "materials",
                                        `${materialIndex}`,
                                        "subMaterials",
                                        `${subIndex}`,
                                        "weight",
                                    ]}
                                    fieldType={fieldConfig.subMaterials.weight.type}
                                    cellType={fieldConfig.subMaterials.weight.cellType}
                                    handleChange={handleInputChange}
                                />
                                <RenderCell
                                    value={subMaterial.density}
                                    path={[
                                        "subProcesses",
                                        `${processIndex}`,
                                        "materials",
                                        `${materialIndex}`,
                                        "subMaterials",
                                        `${subIndex}`,
                                        "density"
                                    ]}
                                    fieldType={fieldConfig.subMaterials.density.type}
                                    cellType={fieldConfig.subMaterials.density.cellType}
                                    handleChange={handleInputChange}
                                />
                                <RenderCell
                                    value={subMaterial.volume}
                                    path={[
                                        "subProcesses",
                                        `${processIndex}`,
                                        "materials",
                                        `${materialIndex}`,
                                        "subMaterials",
                                        `${subIndex}`,
                                        "volume"
                                    ]}
                                    fieldType={fieldConfig.subMaterials.volume.type}
                                    cellType={fieldConfig.subMaterials.volume.cellType}
                                    handleChange={handleInputChange}
                                />
                                <RenderCell
                                    value={subMaterial.leadTime}
                                    path={[
                                        "subProcesses",
                                        `${processIndex}`,
                                        "materials",
                                        `${materialIndex}`,
                                        "subMaterials",
                                        `${subIndex}`,
                                        "leadTime"
                                    ]}
                                    fieldType={fieldConfig.subMaterials.leadTime.type}
                                    cellType={fieldConfig.subMaterials.leadTime.cellType}
                                    handleChange={handleInputChange}
                                />
                                <RenderCell
                                    value={subMaterial.remark}
                                    path={[
                                        "subProcesses",
                                        `${processIndex}`,
                                        "materials",
                                        `${materialIndex}`,
                                        "subMaterials",
                                        `${subIndex}`,
                                        "remark"
                                    ]} fieldType={fieldConfig.subMaterials.remark.type}
                                    cellType={fieldConfig.subMaterials.remark.cellType}
                                    handleChange={handleInputChange}
                                />
                            </tr>
                        ))
                    )}
                    {/* Render details */}
                    {defaultSlurryMixingData.subProcesses[processIndex].details.map((detail, index) => (
                        <tr key={`${processIndex}-detail-${index}`}>
                            <td colSpan={detail.colSpan}>{detail.name}</td>
                            <RenderCell
                                value={detail.weight}
                                path={["subProcesses", "details", index, "weight"]}
                                fieldType={fieldConfig.details.weight.type}
                                cellType={fieldConfig.details.weight.cellType}
                                handleChange={handleInputChange}
                            />
                            <RenderCell
                                value={detail.density}
                                path={["subProcesses", "details", index, "density"]}
                                fieldType={fieldConfig.details.density.type}
                                cellType={fieldConfig.details.density.cellType}
                                handleChange={handleInputChange}
                            />
                            <RenderCell
                                value={detail.volume}
                                path={["subProcesses", "details", index, "volume"]}
                                fieldType={fieldConfig.details.volume.type}
                                cellType={fieldConfig.details.volume.cellType}
                                handleChange={handleInputChange}
                            />
                            <RenderCell
                                value={detail.leadTime}
                                path={["subProcesses", "details", index, "leadTime"]}
                                fieldType={fieldConfig.details.leadTime.type}
                                cellType={fieldConfig.details.leadTime.cellType}
                                handleChange={handleInputChange}
                            />
                            <RenderCell
                                value={detail.remark}
                                path={["subProcesses", "details", index, "remark"]}
                                fieldType={fieldConfig.details.remark.type}
                                cellType={fieldConfig.details.remark.cellType}
                                handleChange={handleInputChange}
                            />
                        </tr>
                    ))}
                </>
            ))}
            {[2, 3, 4].map((processIndex) => (
                defaultSlurryMixingData.subProcesses[processIndex].details.map((detail, index) => (
                    <tr key={`${processIndex}-${index}`}>
                        {index === 0 && (
                            <td rowSpan={defaultSlurryMixingData.subProcesses[processIndex].rowSpan}>
                                {defaultSlurryMixingData.subProcesses[processIndex].name}
                            </td>
                        )}
                        <td colSpan={detail.colSpan}>{detail.name}</td>
                        <td>{detail.weight}</td>
                        <td>{detail.density}</td>
                        <td>{detail.volume}</td>
                        <td>{detail.leadTime}</td>
                        <td>{detail.remark}</td>


                    </tr>
                ))
            ))}

            <tr>
                <td colSpan={defaultSlurryMixingData.total.colSpan}>{defaultSlurryMixingData.total.name}</td>
                <td>{defaultSlurryMixingData.total.weight}</td>
                <td>{defaultSlurryMixingData.total.density}</td>
                <td>{defaultSlurryMixingData.total.volume}</td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    );
};

export default MixingTable;
