import React from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

interface DetailHeaderProps {
    title: string;
    handleEdit: () => void;  // Type for the edit function (void return type)
    handleDelete?: () => void;  // Optional delete function (void return type)
    createdAt?: string;
    updatedAt?: string;
    createdBy?: string;
    updatedBy?: string;
}

const DetailHeader: React.FC<DetailHeaderProps> = ({ title, handleEdit, handleDelete, createdAt, updatedAt, createdBy, updatedBy }) => {
    const lastUpdated = updatedAt ?? createdAt;
    const lastUpdatedDate = lastUpdated ? new Date(lastUpdated).toLocaleString() : 'N/A';
    return (
        <div className="header-section">
            <div className="header-top-row">
                <h1 className="form-title">
                    {title}
                </h1>
                <div className="header-button-group">
                    <button onClick={handleEdit}>
                        <FaEdit />
                    </button>
                    {handleDelete && (
                        <button onClick={handleDelete}>
                            <FaTrashAlt />
                        </button>
                    )}
                </div>
            </div>
            <div className="header-meta">
                Created by: {createdBy} | Updated by: {updatedBy} | Last Updated: {lastUpdatedDate}
            </div>
        </div>
    );
};

export default DetailHeader;
