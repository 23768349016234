import React, { useState } from 'react';
import { useUser } from 'src/auth/UserContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleLogout } from 'src/auth/AuthService';
import logo from 'src/assets/topmaterial-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import NavButton from 'src/components/common/NavButton';
import LanguageSwitcher from './LanguageSwitcher';

const Header: React.FC = () => {
  const { setUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const navigateTo = (path: string, event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event?.metaKey || event?.ctrlKey) {
      window.open(path, '_blank');
    } else {
      navigate(path);
    }
  };

  const isActive = (path: string): boolean => location.pathname.startsWith(path);

  const toggleUserMenu = () => {
    setIsUserMenuOpen((prev) => !prev);
  };

  return (
    <header className="header">
      {/* Logo Section */}
      <div className="header-logo-section">
        <h1 className="header-logo">
          <NavButton path="/main" className="header-logo-button">
            <img src={logo} alt="Logo" className="logo-image" />
          </NavButton>
        </h1>
      </div>

      {/* Navigation Section */}
      <nav className="header-nav">
        <ul className="header-nav-list">
          <li className="header-nav-item">
            <NavButton path="/raw-materials" className={`header-nav-button ${isActive('/raw-materials') ? 'active' : ''}`}>
              재료
            </NavButton>
          </li>
          <li className="header-nav-item">
            <NavButton path="/materials" className={`header-nav-button ${isActive('/materials') ? 'active' : ''}`}>
              물질
            </NavButton>
          </li>
          <li className="header-nav-item">
            <NavButton path="/formulations" className={`header-nav-button ${isActive('/formulations') ? 'active' : ''}`}>
              전극 조성
            </NavButton>
          </li>
          <li className="header-nav-item">
            <NavButton path="/mixing-recipes" className={`header-nav-button ${isActive('/mixing-recipes') ? 'active' : ''}`}>
              믹싱 레시피
            </NavButton>
          </li>
          <li className="header-nav-item">
            <NavButton path="/coating-recipes" className={`header-nav-button ${isActive('/coating-recipes') ? 'active' : ''}`}>
              코팅 레시피
            </NavButton>
          </li>
          <li className="header-nav-item user-menu">
            <button className="user-icon-button" onClick={toggleUserMenu}>
              <FontAwesomeIcon icon={faUserCircle} />
            </button>
            {isUserMenuOpen && (
              <ul className="user-menu-dropdown">
                <li onClick={() => navigateTo('/user-profile')}>My Profile</li>
                <li onClick={() => handleLogout(setUser, navigate)}>Logout</li>
              </ul>
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
