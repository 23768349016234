import axios from 'axios';
import apiConfig from 'src/config/api';
import { isTokenValid } from 'src/auth/tokenUtils';
import { UploadedFile } from '@twinsketch/topika-model';

class FileService {
  static getToken = () => {
    const token = localStorage.getItem('authToken');
    if (!token) throw new Error('Session expired. Please log in again.');
    return token;
  };

  static handleRequest = async (method: 'get' | 'post' | 'delete', url: string, data?: any) => {
    if (!isTokenValid()) throw new Error('Session expired. Please log in again.');

    const token = this.getToken();
    try {
      const response = await axios({
        method,
        url: `${apiConfig.API_BASE_URL}${url}`,
        data,
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        throw new Error('Session expired. Please log in again.');
      }
      console.error(`Error during ${method.toUpperCase()} request to ${url}:`, error);
      throw new Error(`Error during ${method.toUpperCase()} request`);
    }
  };

  // Upload a file to a temporary folder (without materialId)
  static uploadFileWithoutId = async (
    file: File, notes?: string
  ): Promise<UploadedFile> => {
    const formData = new FormData();

    // Encode the file name
    const encodedFile = new File([file], encodeURIComponent(file.name), { type: file.type });
    formData.append('file', encodedFile); // Append the file with encoded name
    if (notes) {
      formData.append('notes', notes);
    }

    // Use the updated backend API to upload a file without material/recipe ID
    return await this.handleRequest('post', '/files/upload/without-id', formData);
  };

  // Upload a file with a materialOrRecipeId
  static uploadFileWithId = async (
    file: File, materialOrRecipeId: string, notes?: string
  ): Promise<UploadedFile> => {
    const formData = new FormData();

    // Encode the file name
    const encodedFile = new File([file], encodeURIComponent(file.name), { type: file.type });
    formData.append('file', encodedFile); // Append the file with encoded name
    formData.append('materialOrRecipeId', materialOrRecipeId); // Include the ID
    if (notes) {
      formData.append('notes', notes);
    }

    // Use the updated backend API to upload a file with material/recipe ID
    return await this.handleRequest('post', '/files/upload/with-id', formData);
  };

  // Move file from temporary folder to materialId folder
  static moveFile = async (tempFileId: string, materialOrRecipeId: string, originalFileName: string, notes?: string):
    Promise<UploadedFile> => {
    const data = {
      tempFileId,
      materialOrRecipeId,
      originalFileName, // Include original file name in request
      notes,            // Optional notes
    };

    console.log(`FE ${originalFileName} `);
    // Expect an UploadedFile response from the backend API
    return await this.handleRequest('post', '/files/move', data);
  };

  // Delete a file by its fileId
  static deleteFile = async (fileId: string, materialOrRecipeId: string) => {
    return await this.handleRequest('delete', `/files/${materialOrRecipeId}/files/${fileId}`);
  };

  // Get all files for a specific material or recipe
  static getFiles = async (materialOrRecipeId: string): Promise<UploadedFile[]> => {
    return await this.handleRequest('get', `/files/${materialOrRecipeId}/files`);
  };
}

export default FileService;
