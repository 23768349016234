import { MixingFormulationWithMaterials, MaterialType } from '@twinsketch/topika-model';
import { FromulationToMixingHelper } from "./FormPage";

/**
 * Translate a MixingFormulationWithMaterials to a FromulationToMixingHelper.
 * @param formulation The input MixingFormulationWithMaterials object.
 * @param activeMaterialKg The active material weight in kg.
 * @returns The translated FromulationToMixingHelper object.
 */
const translateToFromulationToMixingHelper = (
    formulation: MixingFormulationWithMaterials,
    activeMaterialKg: number
): FromulationToMixingHelper => {
    const ratiosByMaterialType: Record<MaterialType, number> = {
        [MaterialType.ACTIVE_MATERIAL]: 0,
        [MaterialType.CONDUCTING_AGENT]: 0,
        [MaterialType.BINDER]: 0,
        [MaterialType.ADDITIVE]: 0,
        [MaterialType.UNSPECIFIED]: 0,
        [MaterialType.SOLVENT]: 0,
        [MaterialType.CURRENT_COLLECTOR]: 0,
        [MaterialType.UNKNOWN]: 0,
    };

    formulation.elements.forEach((element) => {
        const materialType = element.material.materialType;
        if (materialType in ratiosByMaterialType) {
            ratiosByMaterialType[materialType] += element.ratio;
        }
    });

    return {
        ...formulation,
        ratiosByMaterialType,
        activeMaterialKg,
    };
};

function percentageStringToNumber(percentage: string): number {
    // Remove the '%' sign and parse it to a float
    const numericValue = parseFloat(percentage.replace('%', ''));
    // Convert to a decimal number
    return numericValue / 100;
}

export { translateToFromulationToMixingHelper, percentageStringToNumber };
