import React, { useEffect, useState } from "react";
import { ElementWithMaterialDetails, MaterialType } from "@twinsketch/topika-model";
import { FromulationToMixingHelper } from "./FormPage";
import 'src/styles/workOrder/formulation-table.css';

interface FormulationTableProps {
    formulation: FromulationToMixingHelper;
    setFormulation: React.Dispatch<React.SetStateAction<FromulationToMixingHelper | null>>;
    onFormulationUpdate: (updatedFormulation: FromulationToMixingHelper) => void;
}
const DynamicTable: React.FC<{
    materialType: string;
    ratio: number;
    elements: ElementWithMaterialDetails[];
}> = ({ materialType, ratio, elements }) => (
    <tr>
        <th>{materialType}</th>
        <td>
            <span>{ratio}%</span>
        </td>
        <td>
            {elements.length > 0 && (
                <table>
                    <thead>
                        <tr>
                            {elements.map((product, index) => (
                                <th key={`header-${materialType}-${index}`}>{product.material.modelName}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {elements.map((product, index) => (
                                <td key={`product-${materialType}-${index}`}>
                                    <span>
                                        {((product.ratio / ratio) * 100).toFixed(2)}%
                                    </span>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            )}
        </td>
    </tr>
);

const groupElementsByMaterialType = (
    elements: ElementWithMaterialDetails[]
): Record<MaterialType, ElementWithMaterialDetails[]> => {
    return elements.reduce((acc, element) => {
        const type = element.material.materialType;
        if (!acc[type]) {
            acc[type] = [];
        }
        acc[type].push(element);
        return acc;
    }, {} as Record<MaterialType, ElementWithMaterialDetails[]>);
};

const FormulationTable: React.FC<FormulationTableProps> = ({ formulation, setFormulation, onFormulationUpdate }) => {
    const [localFormulation, setLocalFormulation] = useState<FromulationToMixingHelper>(formulation);

    useEffect(() => {
        setLocalFormulation(formulation);
    }, [formulation]);

    const handleActiveMaterialKgChange = (value: number) => {
        const updatedFormulation = { ...localFormulation, activeMaterialKg: value };
        setLocalFormulation(updatedFormulation);
        setFormulation(updatedFormulation);
        onFormulationUpdate(updatedFormulation);
    };

    if (!localFormulation) {
        return <div>Loading...</div>;
    }

    const groupedElements = groupElementsByMaterialType(localFormulation.elements);

    return (
        <div>
            <table className="formulation-table">
                <thead>
                    <tr>
                        <th>Material Type (NCM)</th>
                        <th>Total Ratio (%)</th>
                        <th>Dynamic Columns</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Active Material</th>
                        <td colSpan={2}>
                            <div className="input-wrapper">
                                <input
                                    type="number"
                                    value={localFormulation.activeMaterialKg}
                                    onChange={(e) => handleActiveMaterialKgChange(Number(e.target.value))}
                                />
                                <span className="unit">kg/batch</span>
                            </div>
                        </td>
                    </tr>
                    {Object.entries(localFormulation.ratiosByMaterialType)
                        .filter(([_, ratio]) => ratio > 0)
                        .map(([materialType, ratio], index) => (
                            <DynamicTable
                                key={`ratio-${index}`}
                                materialType={materialType}
                                ratio={ratio}
                                elements={groupedElements[materialType as MaterialType] || []}
                            />
                        ))}
                </tbody>
            </table>
        </div>
    );
};

export default FormulationTable;
