import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from 'src/components/common/Header';
import { MixingRecipeWithDetails } from '@twinsketch/topika-model';
import ApiService from 'src/services/ApiService';
import { useFetchData } from 'src/hook/CustomHook';
import ROUTES from 'src/constants/routes';
import { useTranslation } from 'react-i18next';
import ListHeader from 'src/components/listPage/ListHeader';
import { useAppData } from 'src/components/AppDataContext';

const RecipeList: React.FC = () => {
  const { mixingRecipes, setMixingRecipes } = useAppData();
  const [filteredRecipes, setFilteredRecipes] = useState<MixingRecipeWithDetails[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  useFetchData(ApiService.fetchMixingRecipes, setMixingRecipes, setError);

  useEffect(() => {
    setFilteredRecipes(mixingRecipes);
  }, [mixingRecipes]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = mixingRecipes.filter(recipe => {
      // Check if the recipe name or formulation name includes the search term
      const isRecipeMatch =
        recipe.name.toLowerCase().includes(term) ||
        recipe.formulationName.toLowerCase().includes(term);

      // Check if any material details match the search term
      const isMaterialMatch = recipe.materialsWithDetails.some(detail => {
        const material = detail.material;
        return (
          material.materialName.toLowerCase().includes(term) ||
          material.modelName?.toLowerCase().includes(term) ||
          material.supplier?.toLowerCase().includes(term)
        );
      });

      // Return true if either the recipe or any material details match the search term
      return isRecipeMatch || isMaterialMatch;
    });

    setFilteredRecipes(filtered);
  };

  if (error) {
    return <div className="error"><p>{error}</p></div>;
  }

  return (
    <div>
      <Header />
      <main className="list-main-content">
        <ListHeader
          createRoute={ROUTES.MIXING_RECIPES_CREATE}
          addButtonLabel="등록"
          searchPlaceholder="믹싱 레시피 검색"
          searchTerm={searchTerm}
          handleSearch={handleSearch}
        />
        <div>
          <table>
            <thead>
              <tr>
                <th>{t(`mixingRecipeDataField.name`)}</th>
                <th>{t(`mixingRecipeDataField.formulationName`)}</th>
                <th>{t(`materialTypeEnum.Active Material`)}</th>
                <th>{t(`mixingRecipeDataField.tankCapacity`)}</th>
                <th>{t(`mixingRecipeDataField.activeMaterialKg`)}</th>
              </tr>
            </thead>
            <tbody>
              {filteredRecipes.map((recipe) => {
                // Filter active materials
                const activeMaterials = recipe.materialsWithDetails.filter(detail => detail.material.materialType === "Active Material");

                return (
                  <tr key={recipe.id}>
                    <td><Link to={ROUTES.MIXING_RECIPES_DETAIL(recipe.id)}>{recipe.name}</Link></td>
                    <td><Link to={ROUTES.FORMULATIONS_DETAIL(recipe.formulationId)}>{recipe.formulationName}</Link></td>
                    <td>
                      {activeMaterials.map((materialDetail, index) => (
                        <div key={materialDetail.material.id}>
                          <span>{materialDetail.material.materialName} ({materialDetail.material.modelName})</span>
                          <span> - {materialDetail.ratio}%</span>
                          {index < activeMaterials.length - 1 && <br />} {/* Add line break if more than one active material */}
                        </div>
                      ))}
                    </td>
                    <td>{recipe.tankCapacity}</td>
                    <td>{recipe.activeMaterialKg}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </main>
    </div>

  );
};

export default RecipeList;
