import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ElectrodeType } from '@twinsketch/topika-model';
import { MixingFormulationWithMaterials } from '@twinsketch/topika-model';
import { MaterialDetails, MixingRecipe } from '@twinsketch/topika-model';
import ApiService from 'src/services/ApiService';
import FormField from 'src/components/formPage/FormField';
import { useFetchDataById, useFetchData } from 'src/hook/CustomHook';
import { FileUpload } from 'src/components/file/FileUpload';
import { UploadedFile } from '@twinsketch/topika-model';
import Header from 'src/components/common/Header';
import ROUTES from 'src/constants/routes';
import { FileHandler } from 'src/hook/FileHandler';
import ErrorModal from 'src/components/common/ErrorModal';
import { useTranslation } from 'react-i18next';

const MixingRecipeFormPage: React.FC = () => {
  const { id } = useParams<{ id?: string }>(); // `id` is optional for create
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [formulations, setFormulations] = useState<MixingFormulationWithMaterials[]>([]);
  const [mixingRecipeData, setMixingRecipeData] = useState<
    Omit<MixingRecipe, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>>({
      name: '',
      type: ElectrodeType.UNSPECIFIED,
      formulationId: '',
      tankCapacity: 0,
      activeMaterialKg: 0,
      binderTargetSolidContent: 0,
      slurryTargetSolidContent: 0,
      files: []
    });
  const [existingFiles, setExistingFiles] = useState<any[]>([]);  // Store existing files for update
  const [pendingFiles, setPendingFiles] = useState<any[]>([]);    // Store new files to be uploaded
  const [deletedFilesList, setDeletedFilesList] = useState<UploadedFile[]>([]);
  const [error, setError] = useState<string | null>(null);

  const [selectedFormulationDetails, setSelectedFormulationDetails] = useState<MixingFormulationWithMaterials | null>(null);
  const [mixingDetails, setMixingDetails] = useState<{
    binderMaterials: MaterialDetails[];
    preMixMaterials: MaterialDetails[];
    slurryMaterials: MaterialDetails[];
  } | null>(null);

  useFetchData(ApiService.fetchFormulations, setFormulations, setError);

  // Fetch formulation details if formulationId changes
  useFetchDataById(ApiService.fetchFormulationById, mixingRecipeData.formulationId, setSelectedFormulationDetails, setError);

  // If in edit mode, fetch the existing recipe
  useEffect(() => {
    if (id) {
      const fetchRecipe = async () => {
        try {
          const fetchedRecipe = await ApiService.fetchMixingRecipeById(id);
          setMixingRecipeData(fetchedRecipe);
          setExistingFiles(fetchedRecipe.files || []); // Set existing files
        } catch (err) {
          setError('Error fetching recipe details');
        }
      };
      fetchRecipe();
    }
  }, [id]);

  const fetchMixingDetails = async () => {
    try {
      const response = await ApiService.calculateMixingDetails(mixingRecipeData);
      setMixingDetails(response);
    } catch (err) {
      if (err instanceof Error) {
        setError(`Error fetching mixing details: ${err.message}`);
      } else {
        setError('Unexpected error occurred while fetching mixing details.');
      }
    }
  };

  const handleFileChange = (newPendingFiles: any[], updatedExistingFiles: any[]) => {
    setPendingFiles(newPendingFiles);
    setExistingFiles(updatedExistingFiles);

    setMixingRecipeData((prevData) => ({
      ...prevData,
      files: [
        ...updatedExistingFiles,
        ...newPendingFiles.map((file) => ({
          id: '',
          fileName: file.file.name,
          fileType: file.file.type,
          notes: file.notes,
          s3Url: '',
          uploadedAt: new Date().toISOString(),
        }))
      ]
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;

    setMixingRecipeData(prevState => {
      let updatedValue: any = value;
      if (type === 'number') {
        updatedValue = value === '' ? undefined : Number(value);
      } else if (name === 'type') {
        updatedValue = value as ElectrodeType;
      }

      return {
        ...prevState,
        [name]: updatedValue
      };
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const isValidForm = () => {
      const {
        name,
        formulationId,
        tankCapacity,
        activeMaterialKg,
        binderTargetSolidContent,
        slurryTargetSolidContent,
      } = mixingRecipeData;

      if (!name ||
        !formulationId ||
        tankCapacity <= 0 ||
        activeMaterialKg <= 0 ||
        binderTargetSolidContent <= 0 ||
        slurryTargetSolidContent <= 0) {
        setError('Please complete all fields and ensure valid input values.');
        return false;

      }
      return true;
    };
    if (!isValidForm()) return;

    if (selectedFormulationDetails?.type) {
      mixingRecipeData.type = selectedFormulationDetails.type;
    } else {
      setError('Electrode type is required.');
      return;
    }

    try {
      let createdOrUpdatedId: string;

      if (id) {
        // Update existing recipe
        await FileHandler.deleteFiles(deletedFilesList, id);
        const uploadedFiles = await FileHandler.uploadFilesWithId(pendingFiles, id);
        const updatedRecipe = { ...mixingRecipeData, files: [...existingFiles, ...uploadedFiles] };
        await ApiService.updateMixingRecipe(id, updatedRecipe);
        createdOrUpdatedId = id;
      } else {
        // Create new recipe
        const uploadedFiles = await FileHandler.uploadFilesWithoutId(pendingFiles);
        const createdRecipe = await ApiService.createMixingRecipe({ ...mixingRecipeData, files: uploadedFiles });
        createdOrUpdatedId = createdRecipe.id;
        await FileHandler.moveFiles(uploadedFiles, createdRecipe.id);
      }

      if (createdOrUpdatedId) {
        navigate(ROUTES.MIXING_RECIPES_DETAIL(createdOrUpdatedId));
      }
    } catch (err) {
      setError(id ? 'Error updating recipe' : 'Error creating recipe');
    }
  };

  return (
    <div>
      <Header />
      <ErrorModal message={error} onClose={() => setError(null)} />
      <div className="form-container">
        <h1 className="form-title">{id ? 'Update Recipe' : 'Create Recipe'}</h1>
        <form onSubmit={handleSubmit} className="recipe-form">
          <FormField
            label={t(`mixingRecipeDataField.name`)}
            name="name"
            value={mixingRecipeData.name}
            onChange={handleChange}
            required
            explanation={t(`mixingRecipeDataExplanation.name`)}
          />
          <FormField
            label={t(`mixingRecipeDataField.formulationId`)}
            name="formulationId"
            value={mixingRecipeData.formulationId || ''}
            onChange={handleChange}
            options={formulations.map(formulation => ({
              value: formulation.id,
              label: formulation.name
            }))}
            type="select"
            required
            explanation={t(`mixingRecipeDataExplanation.formulationId`)}
          />
          <FormField
            label={t(`mixingRecipeDataField.tankCapacity`)}
            name="tankCapacity"
            value={String(mixingRecipeData.tankCapacity || '')}
            onChange={handleChange}
            type="number"
            required
            explanation={t(`mixingRecipeDataExplanation.tankCapacity`)}
          />
          <FormField
            label={t(`mixingRecipeDataField.activeMaterialKg`)}
            name="activeMaterialKg"
            value={String(mixingRecipeData.activeMaterialKg || '')}
            onChange={handleChange}
            type="number"
            required
            explanation={t(`mixingRecipeDataExplanation.activeMaterialKg`)}
          />
          <FormField
            label={t(`mixingRecipeDataField.binderTargetSolidContent`)}
            name="binderTargetSolidContent"
            value={String(mixingRecipeData.binderTargetSolidContent || '')}
            onChange={handleChange}
            type="number"
            required
            explanation={t(`mixingRecipeDataExplanation.binderTargetSolidContent`)}
          />
          <FormField
            label={t(`mixingRecipeDataField.slurryTargetSolidContent`)}
            name="slurryTargetSolidContent"
            value={String(mixingRecipeData.slurryTargetSolidContent || '')}
            onChange={handleChange}
            type="number"
            required
            explanation={t(`mixingRecipeDataExplanation.slurryTargetSolidContent`)}
          />
          <h4>Related Files</h4>
          <FileUpload
            onFilesChange={handleFileChange}
            existingFiles={existingFiles}
            onDeleteExistingFile={(fileId) => {
              setDeletedFilesList((prev) => [...prev, existingFiles.find((file) => file.id === fileId)]);
              setExistingFiles((prev) => prev.filter((file) => file.id !== fileId));
            }}
          />
          <button onClick={fetchMixingDetails} type="button" className="submit-button">재료 계산</button>

          {/* Display Mixing Details if available */}
          {mixingDetails && (
            <div className="mixing-details-section">
              {/* Binder Mixing Table */}
              <h3>바인더 믹싱</h3>
              <table className="mixing-table">
                <thead>
                  <tr>
                    <th>{t(`materialDataField.materialName`)}</th>
                    <th>{t(`materialDataField.materialType`)}</th>
                    <th>{t(`mixingRecipeDataField.weight`)}</th>
                  </tr>
                </thead>
                <tbody>
                  {mixingDetails.binderMaterials.map((material, index) => (
                    <tr key={index}>
                      <td>{material.materialName}</td>
                      <td>{t(`materialTypeEnum.${material.materialType}`)}</td>
                      <td>{material.weight}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Slurry Mixing Table */}
              <h3>슬러리 믹싱</h3>
              <table className="mixing-table">
                <thead>
                  <tr>
                    <th>{t(`materialDataField.materialName`)}</th>
                    <th>{t(`materialDataField.materialType`)}</th>
                    <th>{t(`mixingRecipeDataField.weight`)}</th>
                  </tr>
                </thead>
                <tbody>
                  {mixingDetails.slurryMaterials.map((material, index) => (
                    <tr key={index}>
                      <td>{material.materialName}</td>
                      <td>{t(`materialTypeEnum.${material.materialType}`)}</td>
                      <td>{material.weight}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <button onClick={handleSubmit} type="submit" className="submit-button">
            {id ? '수정' : '등록'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default MixingRecipeFormPage;
