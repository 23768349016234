const ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development';

const apiConfig = {
  development: {
    API_BASE_URL: 'http://localhost:8000/v1/api',
  },
  production: {
    API_BASE_URL: 'https://api.topmaterial.twinsketch.com/v1/api',
  },
};

export default apiConfig[ENV as 'development' | 'production'];
