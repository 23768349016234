import React from "react";
import 'src/styles/workOrder/formulation-modal.css'
import { MixingFormulationWithMaterials } from "@twinsketch/topika-model";

interface FormulationModalProps {
    formulations: MixingFormulationWithMaterials[];
    onClose: () => void;
    onSelect: (selectedFormulation: MixingFormulationWithMaterials) => void;
}

const FormulationModal: React.FC<FormulationModalProps> = ({ formulations, onClose, onSelect }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>Select a Formulation</h3>
                <table className="formulation-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Elements</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {formulations.map((formulation) => (
                            <tr key={formulation.id}>
                                <td>{formulation.name}</td>
                                <td>{formulation.type}</td>
                                <td>
                                    {formulation.elements.map(
                                        (element) =>
                                            `${element.material.materialName} (${element.ratio}%)`
                                    ).join(", ")}
                                </td>
                                <td>
                                    <button onClick={() => onSelect(formulation)}>Select</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button className="close-button" onClick={onClose}>
                    Close
                </button>
            </div>
        </div>
    );
};

export default FormulationModal;
