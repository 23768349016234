import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Header from 'src/components/common/Header';
import { CoatingRecipeWithDetails } from '@twinsketch/topika-model';
import ApiService from 'src/services/ApiService';
import { useFetchDataById } from 'src/hook/CustomHook';
import { handleDeleteEntity, handleDeleteFiles, handleEdit } from 'src/hook/CommonHandler';
import { UploadedFile } from '@twinsketch/topika-model';
import FileService from 'src/services/FileService';
import ROUTES from 'src/constants/routes';
import { useTranslation } from 'react-i18next';
import DetailHeader from 'src/components/detailPage/DetailHeader';
import FileSection from 'src/components/file/FileSection';
import ErrorModal from 'src/components/common/ErrorModal';

const CoatingRecipeDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [recipe, setRecipe] = useState<CoatingRecipeWithDetails | null>(null);
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useFetchDataById(ApiService.fetchCoatingRecipeById, id, setRecipe, setError);

  const handleDeleteRecipe = async () => {
    try {
      await handleDeleteFiles(files, id!, setError);
      await handleDeleteEntity(ApiService.deleteCoatingRecipe, id!, ROUTES.COATING_RECIPES, 'Error deleting coating recipe', navigate, setError);
    } catch (err) {
      setError('Error occurred during coating recipe deletion');
    }
  };

  const handleEditRecipe = () =>
    handleEdit(id!, navigate, setError, ROUTES.COATING_RECIPES_EDIT(id!));

  useEffect(() => {
    if (recipe && recipe.files && recipe.files.length > 0) {
      FileService.getFiles(id!)
        .then((fetchedFiles) => setFiles(fetchedFiles))
        .catch((err) => setError(err.message));
    }
  }, [id, recipe]);

  if (!recipe) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Header />
      <ErrorModal message={error} onClose={() => setError(null)} />
      <div className="detail-page">
        <DetailHeader
          title={recipe.name}
          handleEdit={handleEditRecipe}
          handleDelete={handleDeleteRecipe}
          createdAt={recipe.createdAt}
          updatedAt={recipe.updatedAt}
          createdBy={recipe.createdBy}
          updatedBy={recipe.updatedBy}
        />
        <div className="content-section">
          <div className="left-column">
            <div className="table-section">

              {error ? (
                <p>{error}</p>
              ) : recipe ? (
                <table>
                  <tbody>
                    <tr>
                      <td className="field-label">{t(`coatingRecipeDataField.coatingType`)}:</td>
                      <td className="field-value">{recipe.coatingType}</td>
                    </tr>
                    <tr>
                      <td className="field-label">{t(`coatingRecipeDataField.formulationName`)}:</td>
                      <td className="field-value"><Link to={ROUTES.FORMULATIONS_DETAIL(recipe.formulationId)}>{recipe.formulationName}</Link></td>
                    </tr>
                    <tr>
                      <td className="field-label">{t(`coatingRecipeDataField.slurryViscosity`)}:</td>
                      <td className="field-value">{recipe.slurryViscosity}</td>
                    </tr>
                    <tr>
                      <td className="field-label">{t(`coatingRecipeDataField.coatingWidth`)}:</td>
                      <td className="field-value">{recipe.coatingWidth}</td>
                    </tr>
                    <tr>
                      <td className="field-label">{t(`coatingRecipeDataField.shimSpec`)}:</td>
                      <td className="field-value">{recipe.shimSpec}</td>
                    </tr>
                    <tr>
                      <td className="field-label">{t(`coatingRecipeDataField.mixingTankPumpSpeed`)}:</td>
                      <td className="field-value">{recipe.mixingTankPumpSpeed}</td>
                    </tr>
                    <tr>
                      <td className="field-label">{t(`coatingRecipeDataField.coatingPumpSpeed`)}:</td>
                      <td className="field-value">{recipe.coatingPumpSpeed}</td>
                    </tr>
                    <tr>
                      <td className="field-label">{t(`coatingRecipeDataField.slotDieGap`)}:</td>
                      <td className="field-value">{recipe.slotDieGap}</td>
                    </tr>
                    <tr>
                      <td className="field-label">{t(`coatingRecipeDataField.heatingFanSpeedSV`)}:</td>
                      <td className="field-value">{recipe.heatingFanSpeedSV}</td>
                    </tr>
                    <tr>
                      <td className="field-label">{t(`coatingRecipeDataField.heatingTempSV`)}:</td>
                      <td className="field-value">{recipe.heatingTempSV}</td>
                    </tr>
                    <tr>
                      <td className="field-label">{t(`coatingRecipeDataField.exhustFanSpeedSV`)}:</td>
                      <td className="field-value">{recipe.exhustFanSpeedSV}</td>
                    </tr>
                    <tr>
                      <td className="field-label">{t(`coatingRecipeDataField.supplyFanSppedSV`)}:</td>
                      <td className="field-value">{recipe.supplyFanSppedSV}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
          <div className="right-column">
            <FileSection files={files} />
          </div>
        </div>
      </div>
    </div >
  );
};

export default CoatingRecipeDetailPage;
