import React, { useState, useEffect, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ErrorModal from "src/components/common/ErrorModal";
import Header from "src/components/common/Header";
import { MaterialType, MixingFormulationWithMaterials } from '@twinsketch/topika-model';
import FormulationTable from "./FormulationTable";
import 'src/styles/workOrder/work-order.css'
import MixingTable from "./MixingTable";
import BinderSolutionTable from "./BinderMixingTable";
import FormulationModal from "./FormulationModal";
import { useFetchData } from "src/hook/CustomHook";
import ApiService from "src/services/ApiService";
import { translateToFromulationToMixingHelper } from './helper';
import { BinderSolutionData, defaultBinderMixingData, MixingSolutionData, defaultSlurryMixingData } from "./MixingTableData";

export interface FromulationToMixingHelper extends MixingFormulationWithMaterials {
    ratiosByMaterialType: Record<MaterialType, number>;
    activeMaterialKg: number;
}

const WorkOrderForm: React.FC = () => {

    const { id } = useParams<{ id?: string }>();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [error, setError] = useState<string | null>(null);
    const isEditMode = !!id;

    const [binderData, setBinderData] = useState<BinderSolutionData>(defaultBinderMixingData);
    const [mixingData, setMixingData] = useState<MixingSolutionData>(defaultSlurryMixingData);

    const handleBinderDataUpdate = (updatedData: BinderSolutionData) => {
        setBinderData(updatedData);

        // Optionally propagate specific updates to the MixingTable
        const pvdf = updatedData.subProcesses[0].materials[0].subMaterials.find(mat => mat.name === "PVdF");
        const nmp = updatedData.subProcesses[0].materials[0].subMaterials.find(mat => mat.name === "NMP");

        setMixingData((prevData) => {
            const updatedMixingData = { ...prevData };
            const solid = updatedMixingData.subProcesses[0].materials[0].subMaterials.find(mat => mat.name === "Solid");
            const solidWeight = pvdf?.weight || 0; // Replace logic based on your calculation needs
            if (solid) solid.weight = solidWeight;
            // Update other related fields if needed
            return updatedMixingData;
        });
    };

    // State for MixingFormulationWithMaterials
    const [formulations, setFormulations] = useState<MixingFormulationWithMaterials[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFormulation, setSelectedFormulation] = useState<MixingFormulationWithMaterials | null>(null);
    const [detailedFormulation, setDetailedFormulation] = useState<FromulationToMixingHelper | null>(null);

    useFetchData(ApiService.fetchFormulations, setFormulations, setError);


    const handleFormulationSelect = (formulation: MixingFormulationWithMaterials) => {
        setSelectedFormulation(formulation);
        const detailed = translateToFromulationToMixingHelper(formulation, 800);
        setDetailedFormulation(detailed);
        setIsModalOpen(false);

        console.log("Selected Formulation:", formulation);
        console.log("Detailed Formulation:", detailed);
    };

    // State for calculated formulation values
    const [formulationValues, setFormulationValues] = useState({
        activeMaterialKg: 0,
        activeMaterialRatio: 0,
        binderRatio: 0,
    });

    // Handle formulation updates
    const handleFormulationUpdate = (updatedProducts: FromulationToMixingHelper) => {
        // Extract active material and binder information from elements
        const activeMaterialElement = updatedProducts.elements.find(
            (element) => element.materialId === 'NCM1'
        );
        const binderElement = updatedProducts.elements.find(
            (element) => element.materialId === 'Binder1'
        );

        const activeMaterialKg = 800;
        const activeMaterialRatio = activeMaterialElement?.ratio || 0;
        const binderRatio = binderElement?.ratio || 0;

        setFormulationValues({
            activeMaterialKg,
            activeMaterialRatio: activeMaterialRatio / 100,
            binderRatio: binderRatio / 100,
        });
    };

    return (
        <div>
            <Header />
            <ErrorModal message={error} onClose={() => setError(null)} />
            <div className="content-wrapper">
                <button onClick={() => setIsModalOpen(true)}>Select Formulation</button>
                <div className="tables-container">
                    <div className="card important-section">
                        <h2>Formulation and Mixer Tank Volume</h2>
                        <div className="tables-wrapper">
                            <FormulationTable
                                formulation={detailedFormulation} // Ensure detailedFormulation matches this type.
                                setFormulation={setDetailedFormulation} // Pass the correct state setter.
                                onFormulationUpdate={(updatedFormulation) => {
                                    console.log("Updated formulation:", updatedFormulation);
                                    // Handle formulation updates here as needed.
                                    setDetailedFormulation(updatedFormulation);
                                }}
                            />
                            <div className="tank-table-wrapper">
                                <table className="tank-table">
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Volume</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Full</td>
                                            <td>
                                                <div className="input-wrapper">
                                                    <input type="number" value="700.0" className="highlight" />
                                                    <span className="unit">ℓ</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Working</td>
                                            <td>
                                                <div className="input-wrapper">
                                                    <input type="number" value="490.0" className="blue-text" />
                                                    <span className="unit">ℓ</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <FormulationModal
                    formulations={formulations}
                    onClose={() => setIsModalOpen(false)}
                    onSelect={handleFormulationSelect}
                />
            )}
            {selectedFormulation && detailedFormulation && (
                <div>
                    <div
                        className="card"
                        style={{
                            padding: '20px',
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                        }}
                    >
                        <table className="mixing-table">
                            <thead>
                                <tr>
                                    <th colSpan={2}>Process</th>
                                    <th colSpan={2}>Materials</th>
                                    <th>Weight (kg)</th>
                                    <th>Density (g/㎤)</th>
                                    <th>Volume (ℓ)</th>
                                    <th>Lead time (min)</th>
                                    <th>Remark</th>
                                </tr>
                            </thead>
                            <BinderSolutionTable
                                activeMaterialKg={detailedFormulation.activeMaterialKg}
                                materialRatio={detailedFormulation.ratiosByMaterialType}
                                onUpdate={handleBinderDataUpdate} // Pass update handler

                            />
                            <MixingTable
                                activeMaterialKg={detailedFormulation.activeMaterialKg}
                                materialRatio={detailedFormulation.ratiosByMaterialType}
                                formulation={selectedFormulation}
                                binderData={binderData}
                                mixingData={mixingData} // Pass updated data
                            />
                        </table>
                    </div>
                </div>
            )}
        </div >
    );
};

export default WorkOrderForm;
