import React, { useState } from 'react';
import { UserRole } from '@twinsketch/topika-model';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import UserService from 'src/services/UserService';
import { auth } from 'src/config/firebase';

interface SignUpModalProps {
  onClose: () => void;
}

const SignUpModal: React.FC<SignUpModalProps> = ({ onClose }) => {
  const [signUpEmail, setSignUpEmail] = useState('');
  const [signUpPassword, setSignUpPassword] = useState('');
  const [signUpName, setSignUpName] = useState('');
  const [signUpRole, setSignUpRole] = useState('');

  const handleSubmit = async () => {
    if (!signUpName) {
      alert("Name is required");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, signUpEmail, signUpPassword);
      const user = userCredential.user;

      await sendEmailVerification(user);

      const userToCreate = {
        firebaseUserId: user.uid,
        email: signUpEmail,
        name: signUpName,
        emailVerified: false,
        role: (signUpRole || 'guest') as UserRole,
        watchlist: [],
        permissions: {},
      };

      await UserService.createUser(userToCreate);

      alert('회원가입이 완료되었습니다! 인증 이메일이 발송되었습니다. 이메일을 인증한 후 로그인해 주세요.');
      onClose();
    } catch (error: any) {
      console.error("Error in handleSubmit:", error);
      alert("Failed to create user. Please try again.");
    }
  };

  return (
    <div style={{
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div style={{
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        width: '400px',
      }}>
        <h2>사용자등록</h2>

        <input
          type="email"
          placeholder="Email"
          value={signUpEmail}
          onChange={(e) => setSignUpEmail(e.target.value)}
          style={{ width: '90%', padding: '10px', marginBottom: '15px', border: '1px solid #ccc', borderRadius: '4px' }}
        />

        <input
          type="password"
          placeholder="Password"
          value={signUpPassword}
          onChange={(e) => setSignUpPassword(e.target.value)}
          style={{ width: '90%', padding: '10px', marginBottom: '15px', border: '1px solid #ccc', borderRadius: '4px' }}
        />

        <input
          type="text"
          placeholder="Name (required)"
          value={signUpName}
          onChange={(e) => setSignUpName(e.target.value)}
          style={{ width: '90%', padding: '10px', marginBottom: '15px', border: '1px solid #ccc', borderRadius: '4px' }}
        />

        <select
          value={signUpRole}
          onChange={(e) => setSignUpRole(e.target.value)}
          style={{ width: '90%', padding: '10px', marginBottom: '15px', border: '1px solid #ccc', borderRadius: '4px' }}
        >
          <option value="">Select Role (optional)</option>
          <option value="super">Super</option>
          <option value="admin">Admin</option>
          <option value="user">User</option>
          <option value="guest">Guest</option>
        </select>

        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <button
            style={{ padding: '10px 15px', border: 'none', borderRadius: '4px', backgroundColor: '#28a745', color: '#fff', cursor: 'pointer' }}
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            style={{ padding: '10px 15px', border: 'none', borderRadius: '4px', backgroundColor: '#dc3545', color: '#fff', cursor: 'pointer' }}
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUpModal;
