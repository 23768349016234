import { UploadedFile } from '@twinsketch/topika-model';
import FileService from "src/services/FileService";

export const handleDeleteFiles = async (
  files: UploadedFile[],
  id: string,
  setError: (message: string | null) => void
) => {
  try {
    if (files.length > 0) {
      for (const file of files) {
        await FileService.deleteFile(file.id, id);
      }
    }
  } catch (err: any) {
    setError('Error deleting associated files');
  }
};

export const handleDeleteEntity = async (
  deleteFunction: (id: string) => Promise<void>, // The delete function to call
  id: string,                                   // The ID of the item to delete
  redirectPath: string,                         // The path to navigate to after deletion
  errorMessage: string,                         // The error message to display on failure
  navigate: (path: string) => void,             // Navigation function
  setError: (message: string | null) => void    // Error handling function
) => {
  try {
    await deleteFunction(id);
    navigate(redirectPath);
  } catch (err: any) {
    if (err.message.includes('Session expired')) {
      setError('Session expired. Please log in again.');
      navigate('/login');
    } else {
      setError(errorMessage);
    }
  }
};

export const handleEdit = (
  id: string,                                    // The ID of the item to edit
  navigate: (path: string) => void,              // Navigation function
  setError: (message: string | null) => void,    // Error handling function
  editPath: string                               // Path to redirect to edit page
) => {
  try {
    // Additional session check logic can be added here if needed
    navigate(editPath.replace(':id', id)); // Replace placeholder with actual ID and navigate
  } catch (err: any) {
    if (err.message.includes('Session expired')) {
      setError('Session expired. Please log in again.');
      navigate('/login');
    } else {
      setError('Error navigating to the edit page');
    }
  }
};