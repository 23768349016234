/**
 * Represents the detailed information of a sub-material.
 */
export interface SubMaterial {
    name: string;
    weight: number;
    density: number;
    volume: number;
    leadTime: string;
    remark: string;
}

/**
 * Represents a material, which contains multiple sub-materials.
 */
export interface Material {
    name: string;
    subMaterials: SubMaterial[];
    rowSpan?: number; // Optional: Required only for mixing-specific implementations
}

/**
 * Represents additional details or steps within a subprocess.
 */
export interface Detail {
    name: string;
    colSpan: number;
    weight: number;
    density: number;
    volume: number;
    leadTime: string | number;
    remark: string;
}

/**
 * Represents a subprocess within the overall solution.
 */
export interface SubProcess {
    name: string;
    materials: Material[];
    details: Detail[];
    rowSpan?: number; // Optional: Required only for mixing-specific implementations
}

/**
 * Represents the total summary of the solution.
 */
export interface TotalDetail {
    name: string;
    colSpan: number;
    weight: number;
    density: number;
    volume: number;
    leadTime: string;
    remark: string;
}

/**
 * Base interface for solution data, shared by both binder and mixing solutions.
 */
export interface SolutionData {
    process: string;
    subProcesses: SubProcess[];
    total: TotalDetail;
}

/**
 * Specific implementation for binder solutions.
 */
export interface BinderSolutionData extends SolutionData {
    // No additional fields for binder-specific data
}

/**
 * Specific implementation for mixing solutions.
 */
export interface MixingSolutionData extends SolutionData {
    rowSpan: number; // Overall rowSpan for the mixing process
    subProcesses: MixingSubProcess[];
}

/**
 * Mixing-specific version of SubProcess.
 */
export interface MixingSubProcess extends SubProcess {
    rowSpan: number; // Required for mixing-specific implementations
    materials: MixingMaterial[];
}

/**
 * Mixing-specific version of Material.
 */
export interface MixingMaterial extends Material {
    rowSpan: number; // Required for mixing-specific implementations
    subMaterials: MixingSubMaterial[];
}

/**
 * Mixing-specific version of SubMaterial.
 */
export interface MixingSubMaterial extends SubMaterial {
    // Add mixing-specific properties here if necessary
}

export const defaultSlurryMixingData: MixingSolutionData = {
    process: "Make slurry (700L PDM)",
    rowSpan: 16,
    subProcesses: [
        {
            name: "Pre-mix",
            rowSpan: 5,
            materials: [
                {
                    name: "Additive BM730H",
                    rowSpan: 3,
                    subMaterials: [
                        { name: "Solid", weight: null, density: null, volume: null, leadTime: "", remark: "8%" },
                        { name: "NMP", weight: 4.79, density: null, volume: null, leadTime: "", remark: "" },
                        { name: "Total", weight: 5.21, density: 1.03, volume: 5.06, leadTime: "", remark: "" },
                    ],
                },
                {
                    name: "CA",
                    rowSpan: 1,
                    subMaterials: [
                        { name: "LITXHP", weight: 16.67, density: 1.95, volume: 8.55, leadTime: "", remark: "" },
                    ],
                },
            ],
            details: [
                { name: "Mixing", colSpan: 2, weight: null, density: null, volume: null, leadTime: 30, remark: "" },
            ],
        },
        {
            name: "Main-mix",
            rowSpan: 3,
            materials: [
                {
                    name: "AM NCM",
                    rowSpan: 2,
                    subMaterials: [
                        { name: "S85E", weight: 560, density: 4.75, volume: 117.89, leadTime: "", remark: "8%" },
                        { name: "SC70", weight: 240, density: 4.75, volume: 50.53, leadTime: "", remark: "" },
                    ],
                },

            ],
            details: [
                { name: "Mixing", colSpan: 2, weight: null, density: null, volume: null, leadTime: 120, remark: "" },
            ],
        },
        {
            name: "Slurry",
            rowSpan: 3,
            materials: [],
            details: [
                { name: "Total Solid Weight", colSpan: 2, weight: 833.33, density: null, volume: null, leadTime: "", remark: "", },
                { name: "Total Weight", colSpan: 2, weight: 1092.71, density: 2.49, volume: 438.4, leadTime: "", remark: "", },
                { name: "% Solid content", colSpan: 2, weight: 76.26, density: null, volume: null, leadTime: "", remark: "", },
            ],
        },
        {
            name: "Dilution",
            rowSpan: 3,
            materials: [],
            details: [
                { name: "NMP", colSpan: 2, weight: null, density: 1.03, volume: null, leadTime: "", remark: "", },
                { name: "Mixing", colSpan: 2, weight: null, density: null, volume: null, leadTime: "30", remark: "", },
                { name: "% Solid content", colSpan: 2, weight: 76.26, density: null, volume: null, leadTime: "", remark: "", },
            ],
        },
        {
            name: "Degasing",
            rowSpan: 1,
            materials: [],
            details: [
                { name: "Agitating", colSpan: 2, weight: -0.09, density: null, volume: null, leadTime: "30", remark: "", },
            ],
        }
    ],
    total: {
        name: "Total Slurry Weight",
        colSpan: 3,
        weight: 1092.71,
        density: 2.49,
        volume: 438.37,
        leadTime: "390.0",
        remark: "",
    },
};

export const defaultBinderMixingData: BinderSolutionData = {
    process: "Make binder solution (700L PDM)",
    subProcesses: [
        {
            name: "Dosing & Mixing",
            rowSpan: 4,
            materials: [
                {
                    name: "PVdf S5140",
                    rowSpan: 2,
                    subMaterials: [
                        { name: "NMP", weight: 0, density: 1.03, volume: 0, leadTime: "", remark: "" },
                        { name: "PVdF", weight: 0, density: 1.77, volume: 0, leadTime: "", remark: "" },
                    ],
                },
            ],
            details: [
                { name: "Mixing", colSpan: 2, weight: null, density: null, volume: null, leadTime: "180", remark: "" },
                { name: "% Solid content", colSpan: 2, weight: 6.0, density: null, volume: null, leadTime: "", remark: "" },
            ],
        },
    ],
    total: {
        name: "Total",
        colSpan: 3,
        weight: 270.83,
        density: 1.06,
        volume: 256.35,
        leadTime: "",
        remark: "",
    },
};
