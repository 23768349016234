import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PlatformUser } from '@twinsketch/topika-model';
import UserService from 'src/services/UserService';
import BackButton from 'src/components/common/BackButton';
import ErrorModal from 'src/components/common/ErrorModal';
import Header from 'src/components/common/Header';
import ROUTES from 'src/constants/routes';


const UserProfile: React.FC = () => {
    const [user, setUser] = useState<PlatformUser | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                setLoading(true);
                const fetchedUser = await UserService.getUser();
                setUser(fetchedUser);
            } catch (err: any) {
                setError('Failed to fetch user.');
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!user) {
        return <div>No user data available.</div>;
    }

    return (
        <div>
            <Header />
            <ErrorModal message={error} onClose={() => setError(null)} />
            <div className="form-page">
                <h1>Profile</h1>
                <p><strong>Name:</strong> {user.name}</p>
                <p><strong>Email:</strong> {user.email}</p>
            </div>
        </div>
    );
};

export default UserProfile;
