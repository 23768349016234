import axios from 'axios';
import apiConfig from '../config/api';
import { isTokenValid } from 'src/auth/tokenUtils';
import { PlatformUser, UserRole } from '@twinsketch/topika-model';

class UserService {
    static getToken = () => {
        const token = localStorage.getItem('authToken');
        if (!token) throw new Error('Session expired. Please log in again.');
        return token;
    };
    static handleRequest = async (method: 'get' | 'post' | 'put' | 'delete', url: string, checkToken: boolean, data?: any) => {
        // Debugging logs
        // console.log("Entering handleRequest");
        // console.log("Method:", method);
        // console.log("URL:", url);
        // console.log("checkToken value:", checkToken);
        // console.log("checkToken type:", typeof checkToken);

        if (checkToken) {
            if (!isTokenValid()) {
                console.log("Token is invalid");
                throw new Error('Session expired. Please log in again.');
            }
        }

        try {
            const response = await axios({
                method,
                url: `${apiConfig.API_BASE_URL}${url}`,
                data,
                headers: checkToken ? { Authorization: `Bearer ${this.getToken()}` } : undefined,
            });
            console.log("Response received:", response.data);
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                console.error("Unauthorized error (401):", error.response.data);
                throw new Error('Session expired. Please log in again.');
            }
            console.error(`Error during ${method.toUpperCase()} request to ${url}:`, error);
            throw new Error(`Error during ${method.toUpperCase()} request`);
        }
    };

    static async createUser(
        newUser: Omit<PlatformUser, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>
    ): Promise<PlatformUser> {
        // Set default values. New user always created as guest

        const newUserData = {
            ...newUser,
            role: "guest" as UserRole,
            emailVerified: false,
            watchlist: [],
            permissions: {},
        };

        return this.handleRequest('post', '/users', false, newUserData);
    };

    static async getUser(): Promise<PlatformUser> {
        // Set default values. New user always created as guest
        console.log("hello22")

        return this.handleRequest('get', '/users', true);
    }

    static async generateToken({ uid }: { uid: string }): Promise<string> {
        // Define the payload to send to the backend for token generation
        const payload = { uid };
        // Use handleRequest to send a POST request to the backend endpoint
        const tokenResponse = await this.handleRequest('post', '/users/token', false, payload);

        // Return the token received from the backend
        return tokenResponse.token;
    }
}

export default UserService;