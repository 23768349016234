import React, { useEffect, useState } from "react";
import ErrorModal from "src/components/common/ErrorModal";
import Header from "src/components/common/Header";
import ApiService from 'src/services/ApiService';
import { useTranslation } from 'react-i18next';
import { useFetchDataById } from 'src/hook/CustomHook';
import { RawMaterial } from '@twinsketch/topika-model';
import { useNavigate, useParams } from "react-router-dom";
import DetailHeader from "src/components/detailPage/DetailHeader";
import { handleDeleteEntity, handleDeleteFiles, handleEdit } from 'src/hook/CommonHandler';
import FileSection from "src/components/file/FileSection";
import ROUTES from 'src/constants/routes';
import { UploadedFile } from '@twinsketch/topika-model';
import FileService from "src/services/FileService";
import { FaInfoCircle } from "react-icons/fa";
import { getFieldsForRawMaterial } from "src/components/dataFields/RawMaterialFields";
import BackButton from "src/components/common/BackButton";

const RawMaterialDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [rawMaterial, setRawMaterial] = useState<RawMaterial | null>(null);
    const [previousRawMaterial, setPreviousRawMaterial] = useState<RawMaterial | null>(null);
    const [showPreviousVersion, setShowPreviousVersion] = useState(false);
    const [files, setFiles] = useState<UploadedFile[]>([]);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useFetchDataById(ApiService.fetchRawMaterialById, id, setRawMaterial, setError);

    const isPreviousVersionAvailable = !!rawMaterial?.updatedAt && !!rawMaterial?.updatedBy;

    const handleDeleteRM = async () => {
        try {
            if (id) {
                await handleDeleteFiles(files, id, setError);
                await handleDeleteEntity(
                    ApiService.deleteRawMaterial, id, ROUTES.RAW_MATERIALS, 'Error deleting raw material', navigate, setError);
            }
        } catch (err) {
            setError('Error occurred during material deletion');
        }
    };

    const handleEditRM = () => {
        if (id) {
            handleEdit(id, navigate, setError, ROUTES.RAW_MATERIALS_EDIT(id));
        }
    };

    const fetchPreviousVersion = async () => {
        console.log(id)
        if (id) {
            try {
                const fetchedPreviousRawMaterial = await ApiService.fetchRawMaterialById(id, 'v1');
                setPreviousRawMaterial(fetchedPreviousRawMaterial);
            } catch (error) {
                setError("Error fetching previous version");
            }
        }
    };

    const togglePreviousVersion = () => {
        setShowPreviousVersion(!showPreviousVersion);
        if (!previousRawMaterial) {
            fetchPreviousVersion();
        }
    };

    useEffect(() => {
        if (rawMaterial && rawMaterial.files && rawMaterial.files.length > 0 && id) {
            FileService.getFiles(id)
                .then((fetchedFiles) => setFiles(fetchedFiles))
                .catch((err) => setError(err.message));
        }
    }, [id, rawMaterial]);

    if (!rawMaterial) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Header />
            <BackButton route={ROUTES.RAW_MATERIALS} />
            <ErrorModal message={error} onClose={() => setError(null)} />
            <div className="detail-page">
                <DetailHeader
                    title={rawMaterial.productName}
                    handleEdit={handleEditRM}
                    handleDelete={handleDeleteRM}
                    createdAt={rawMaterial.createdAt}
                    updatedAt={rawMaterial.updatedAt}
                    createdBy={rawMaterial.createdBy}
                    updatedBy={rawMaterial.updatedBy}
                />

                {/* Toggle Previous Version Switch */}
                <div className="toggle-container">
                    <label className="toggle-switch">
                        <input
                            type="checkbox"
                            onChange={togglePreviousVersion}
                            checked={showPreviousVersion}
                            disabled={!isPreviousVersionAvailable}
                        />
                        <span className="slider"></span>
                    </label>
                    <span className="toggle-description">
                        {isPreviousVersionAvailable
                            ? showPreviousVersion
                                ? t('Hide Previous Version')
                                : t('Show Previous Version')
                            : t('No Previous Version Available')}
                    </span>
                </div>

                <div className="content-section">
                    <div className="left-column">
                        <div className="table-section">
                            <table>
                                <thead>
                                    <tr>
                                        <th>{t('Field')}</th>
                                        <th>{t('Current Value')}</th>
                                        {showPreviousVersion && <th>{t('Previous Value')}</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {getFieldsForRawMaterial()
                                        .filter(fieldConfig =>
                                            rawMaterial[fieldConfig.name as keyof typeof rawMaterial] !== undefined &&
                                            rawMaterial[fieldConfig.name as keyof typeof rawMaterial] !== null
                                        )
                                        .map(fieldConfig => {
                                            const currentValue = rawMaterial[fieldConfig.name as keyof typeof rawMaterial];
                                            const previousValue = previousRawMaterial?.[fieldConfig.name as keyof typeof rawMaterial];

                                            return (
                                                <tr key={fieldConfig.name}>
                                                    <td>
                                                        {t(`rawMaterialDataField.${fieldConfig.name}`, fieldConfig.name)}
                                                        <span className="info-icon" data-tooltip={t(`materialDataExplanation.${fieldConfig.name}`)}>
                                                            <FaInfoCircle />
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {Array.isArray(currentValue)
                                                            ? `${currentValue.length} files attached`
                                                            : currentValue?.toString() ?? '-'}
                                                    </td>
                                                    {showPreviousVersion && (
                                                        <td>
                                                            {Array.isArray(previousValue)
                                                                ? `${(previousValue as UploadedFile[]).length} files attached`
                                                                : previousValue?.toString() ?? '-'}
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="right-column">
                        <FileSection files={files} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RawMaterialDetail;
