// src/utils/mixingRecipeUtils.ts
import { CoatingRecipe } from '@twinsketch/topika-model';
import { MixingRecipe } from '@twinsketch/topika-model';

export function prepareMixingRecipeData(
    data: Omit<MixingRecipe, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>
): Omit<MixingRecipe, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'> {
    return {
        name: data.name,
        type: data.type,
        formulationId: data.formulationId,
        tankCapacity: data.tankCapacity,
        activeMaterialKg: data.activeMaterialKg,
        binderTargetSolidContent: data.binderTargetSolidContent,
        slurryTargetSolidContent: data.slurryTargetSolidContent,
        files: data.files,
    };
}

export function prepareCoatingRecipeData(
    data: Omit<CoatingRecipe, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>
): Omit<CoatingRecipe, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'> {
    return {
        name: data.name,
        formulationId: data.formulationId,
        slurryViscosity: data.slurrySolidContent,
        slurrySolidContent: data.slurrySolidContent,
        coatingType: data.coatingType,
        coatingWidth: data.coatingWidth,
        shimSpec: data.shimSpec,
        mixingTankPumpSpeed: data.mixingTankPumpSpeed,
        coatingPumpSpeed: data.coatingPumpSpeed,
        slotDieGap: data.slotDieGap,
        heatingFanSpeedSV: data.heatingFanSpeedSV,
        heatingTempSV: data.heatingTempSV,
        exhustFanSpeedSV: data.exhustFanSpeedSV,
        supplyFanSppedSV: data.supplyFanSppedSV,
        files: data.files,
    };
}
