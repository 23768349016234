import React, { useEffect, useState } from "react";
import { BinderSolutionData, defaultBinderMixingData } from "./MixingTableData";
import RenderCell, { FieldConfig } from "./RenderCell";
import { MaterialType } from "@twinsketch/topika-model";
import 'src/styles/workOrder/mixing-table.css';

const fieldConfig: FieldConfig = {
    subMaterials: {
        weight: { cellType: "calculated", type: "number" },
        density: { cellType: "editable", type: "number" },
        volume: { cellType: "calculated", type: "number" },
        leadTime: { cellType: "editable", type: "text" },
        remark: { cellType: "editable", type: "text" },
    },
    details: {
        weight: { cellType: "editable", type: "number" },
        density: { cellType: "disabled", type: "number" },
        volume: { cellType: "disabled", type: "number" },
        leadTime: { cellType: "editable", type: "text" },
        remark: { cellType: "editable", type: "text" },
    },
    total: {
        weight: { cellType: "calculated", type: "number" },
        density: { cellType: "calculated", type: "number" },
        volume: { cellType: "calculated", type: "number" },
    },
};

interface BinderSolutionTableProps {
    activeMaterialKg: number;
    materialRatio: Record<MaterialType, number>;
    onUpdate: (updatedData: BinderSolutionData) => void;
}

const BinderSolutionTable: React.FC<BinderSolutionTableProps> = ({
    activeMaterialKg,
    materialRatio,
    onUpdate,
}) => {
    const [binderSolutionData, setBinderSolutionData] = useState<BinderSolutionData>(
        defaultBinderMixingData
    );

    const calculateTotals = (subMaterials: any[]) => {
        const totalWeight = subMaterials.reduce((sum, mat) => sum + (mat.weight || 0), 0);
        const totalVolume = subMaterials.reduce((sum, mat) => sum + (mat.volume || 0), 0);
        const averageDensity = totalVolume > 0 ? totalWeight / totalVolume : 0;

        return {
            name: "Total",
            colSpan: 3,
            weight: parseFloat(totalWeight.toFixed(2)),
            density: parseFloat(averageDensity.toFixed(2)),
            volume: parseFloat(totalVolume.toFixed(2)),
            leadTime: "",
            remark: "",
        };
    };

    const activeMaterialRatio = materialRatio["Active Material"];
    const binderRatio = materialRatio["Binder"];

    // Update PVDF weight and totals dynamically
    useEffect(() => {
        setBinderSolutionData((prevData) => {
            const updatedData = { ...prevData };
            const subMaterials = updatedData.subProcesses[0].materials[0].subMaterials;

            // Update PVdF weight and volume
            const pvdf = subMaterials.find((mat) => mat.name === "PVdF");
            if (pvdf) {
                const pvdfWeight = activeMaterialKg * (binderRatio / activeMaterialRatio);
                pvdf.weight = parseFloat(pvdfWeight.toFixed(2));
                pvdf.volume = pvdf.density
                    ? parseFloat((pvdf.weight / pvdf.density).toFixed(2))
                    : null;
            }

            // Update NMP weight and volume
            const nmp = subMaterials.find((mat) => mat.name === "NMP");
            if (nmp) {
                const nmpWeight = (activeMaterialKg * (binderRatio / activeMaterialRatio)) * (1 / 0.06 - 1);
                nmp.weight = parseFloat(nmpWeight.toFixed(2));
                nmp.volume = nmp.density
                    ? parseFloat((nmp.weight / nmp.density).toFixed(2))
                    : null;
            }

            // Recalculate totals
            updatedData.total = calculateTotals(subMaterials);

            return updatedData;
        });
    }, [activeMaterialKg, activeMaterialRatio, binderRatio]);

    const handleChange = (value: any, path: string[]) => {
        setBinderSolutionData((prevData) => {
            const updatedData = { ...prevData };
            let current: any = updatedData;

            // Traverse to the field
            for (let i = 0; i < path.length - 1; i++) {
                current = current[path[i]];
            }

            current[path[path.length - 1]] = value;

            // Recalculate volumes and totals if relevant fields changed
            if (["weight", "density"].includes(path[path.length - 1])) {
                const subMaterials = updatedData.subProcesses[0].materials[0].subMaterials;

                subMaterials.forEach((mat) => {
                    if (mat.weight && mat.density) {
                        mat.volume = parseFloat((mat.weight / mat.density).toFixed(2));
                    } else {
                        mat.volume = null;
                    }
                });

                updatedData.total = calculateTotals(subMaterials);
            }

            return updatedData;
        });
    };

    return (
        <tbody>
            {binderSolutionData.subProcesses[0].materials[0].subMaterials.map((material, index) => (
                <tr key={index}>
                    {index === 0 && (
                        <>
                            <td rowSpan={binderSolutionData.subProcesses[0].details.length +
                                binderSolutionData.subProcesses[0].materials[0].subMaterials.length + 1 || 0}>
                                {binderSolutionData.process}
                            </td>
                            <td rowSpan={binderSolutionData.subProcesses[0].details.length +
                                binderSolutionData.subProcesses[0].materials[0].subMaterials.length || 0}>
                                {binderSolutionData.subProcesses[0].name}
                            </td>
                            <td rowSpan={binderSolutionData.subProcesses[0].materials[0].subMaterials.length || 0}>
                                {binderSolutionData.subProcesses[0].materials[0].name}
                            </td>
                        </>
                    )}
                    <td>{material.name}</td>
                    <RenderCell
                        value={material.weight}
                        path={["subProcesses", "0", "materials", "0", "subMaterials", `${index}`, "weight"]}
                        fieldType={fieldConfig.subMaterials.weight.type}
                        cellType={fieldConfig.subMaterials.weight.cellType}
                        handleChange={handleChange}
                    />
                    <RenderCell
                        value={material.density}
                        path={["subProcesses", "0", "materials", "0", "subMaterials", `${index}`, "density"]}
                        fieldType={fieldConfig.subMaterials.density.type}
                        cellType={fieldConfig.subMaterials.density.cellType}
                        handleChange={handleChange}
                    />
                    <RenderCell
                        value={material.volume}
                        path={["subProcesses", "0", "materials", "0", "subMaterials", `${index}`, "volume"]}
                        fieldType={fieldConfig.subMaterials.volume.type}
                        cellType={fieldConfig.subMaterials.volume.cellType}
                        handleChange={handleChange}
                    />
                    <RenderCell
                        value={material.leadTime}
                        path={["subProcesses", "0", "materials", "0", "subMaterials", `${index}`, "leadTime"]}
                        fieldType={fieldConfig.subMaterials.leadTime.type}
                        cellType={fieldConfig.subMaterials.leadTime.cellType}
                        handleChange={handleChange}
                    />
                    <RenderCell
                        value={material.remark}
                        path={["subProcesses", "0", "materials", "0", "subMaterials", `${index}`, "remark"]}
                        fieldType={fieldConfig.subMaterials.remark.type}
                        cellType={fieldConfig.subMaterials.remark.cellType}
                        handleChange={handleChange}
                    />
                </tr>
            ))}
            {binderSolutionData.subProcesses[0].details.map((detail, index) => (
                <tr key={index}>
                    <td colSpan={detail.colSpan}>{detail.name}</td>
                    <RenderCell
                        value={detail.weight}
                        path={["subProcesses", "details", index, "weight"]}
                        fieldType={fieldConfig.details.weight.type}
                        cellType={fieldConfig.details.weight.cellType}
                        handleChange={handleChange}
                    />
                    <RenderCell
                        value={detail.density}
                        path={["subProcesses", "details", index, "density"]}
                        fieldType={fieldConfig.details.density.type}
                        cellType={fieldConfig.details.density.cellType}
                        handleChange={handleChange}
                    />
                    <RenderCell
                        value={detail.volume}
                        path={["subProcesses", "details", index, "volume"]}
                        fieldType={fieldConfig.details.volume.type}
                        cellType={fieldConfig.details.volume.cellType}
                        handleChange={handleChange}
                    />
                    <RenderCell
                        value={detail.leadTime}
                        path={["subProcesses", "details", index, "leadTime"]}
                        fieldType={fieldConfig.details.leadTime.type}
                        cellType={fieldConfig.details.leadTime.cellType}
                        handleChange={handleChange}
                    />
                    <RenderCell
                        value={detail.remark}
                        path={["subProcesses", "details", index, "remark"]}
                        fieldType={fieldConfig.details.remark.type}
                        cellType={fieldConfig.details.remark.cellType}
                        handleChange={handleChange}
                    />
                </tr>
            ))}
            <tr>
                <td colSpan={binderSolutionData.total.colSpan}>{binderSolutionData.total.name}</td>
                <RenderCell
                    value={binderSolutionData.total.weight}
                    path={["total", "weight"]}
                    fieldType={fieldConfig.total.weight.type}
                    cellType={fieldConfig.total.weight.cellType}
                    handleChange={handleChange}
                />
                <RenderCell
                    value={binderSolutionData.total.density}
                    path={["total", "density"]}
                    fieldType={fieldConfig.total.density.type}
                    cellType={fieldConfig.total.density.cellType}
                    handleChange={handleChange}
                />
                <RenderCell
                    value={binderSolutionData.total.volume}
                    path={["total", "volume"]}
                    fieldType={fieldConfig.total.volume.type}
                    cellType={fieldConfig.total.volume.cellType}
                    handleChange={handleChange}
                />
                <td></td>
                <td></td>
            </tr>
        </tbody>
    );
};

export default BinderSolutionTable;